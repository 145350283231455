.app-list-item {
	color:$base-text-color;

	&:hover {
		border: 1px solid lighten(lighten($black, 75%), 14%);
	}

	&:active {
		border-color: $primary-500;
	}
}