%ball{
  background:$primary-500;
  color:$white !important;
 
    &:hover {
      background: $primary-700;
    }
}

.menu-item,
label.menu-open-button {
  @extend %ball;
}

.app-shortcut-icon {
  background: #ecf0f1;
  color: #ecf0f1;
}

.menu-open:checked+.menu-open-button{
  background: $fusion-500;
}
