/* #THEME COLOR (variable overrides)
========================================================================== */
$color-primary: #dd5293;
$color-success: #1dc9b7; 
$color-info: #2196F3;
$color-warning: #ffc241; 
$color-danger: #fd3995;

/* #GLOBAL IMPORTS
========================================================================== */
@import './src/scss/_imports/_theme-modules-import';

/* #Reset userselect
========================================================================== */
#myapp-0 {
	box-shadow: none !important;
}
#myapp-6 {
	box-shadow: 0 0 0 3px #000000;
}