//bootstrap button colors
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//border
@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

// Typography
@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}